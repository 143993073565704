<template>
    <div id="sc-new-customer">
        <b-button class="sc-button-back" @click="$router.back()"> <b-icon icon="arrow-left-circle-fill" aria-hidden="true"></b-icon> Back </b-button>

        <h3>New Customer</h3>
        <hr />
        <b-form @submit="onSubmitNewCustomer">
            <b-form-group id="sc-companyname-container" label="Company Name">
                <b-form-input v-model="form.company" required placeholder="Enter company name"></b-form-input>
            </b-form-group>

            <b-form-group id="sc-customername-container" label="Customer Name">
                <b-form-input v-model="form.name" required placeholder="Enter customer name"></b-form-input>
            </b-form-group>

            <b-form-group id="sc-balance-container" label="Balance">
                <b-form-input v-model="form.balance" type="text" placeholder="Enter balance" min="0"></b-form-input>
            </b-form-group>

            <b-form-group id="sc-carryover-container" label="Prepay">
                <b-form-input v-model="form.carryOver" type="text" placeholder="Enter prepay" min="0"></b-form-input>
            </b-form-group>

            <b-form-group id="sc-standing-container" label="Standing (1, 2 or 3)">
                <b-form-input v-model="form.standing" type="text" placeholder="Enter standing" min="0"></b-form-input>
            </b-form-group>

            <b-form-group id="sc-email-container" label="Email address">
                <b-form-input v-model="form.email" type="text" placeholder="Enter email"></b-form-input>
            </b-form-group>

            <b-form-group id="sc-phone-container" label="Phone">
                <b-form-input v-model="form.phone" type="text" placeholder="Enter phone"></b-form-input>
            </b-form-group>

            <b-form-group id="sc-truck-container" label="Truck">
                <b-form-input v-model="form.truck" type="text" placeholder="Enter truck"></b-form-input>
            </b-form-group>

            <b-form-group id="sc-quickbooksid-container" label="Quickbooks Id">
                <b-form-input v-model="form.quickbooksId" type="text" placeholder="Enter Quickbooks id"></b-form-input>
            </b-form-group>

            <b-form-group id="sc-productsid-container" label="Products Id">
                <b-form-input v-model="form.productsId" type="text" placeholder="Enter Products id"></b-form-input>
            </b-form-group>

            <b-form-group id="sc-address-container" label="Address">
                <b-form-textarea v-model="form.address" placeholder="Enter address" rows="3" max-rows="4"></b-form-textarea>
            </b-form-group>

            <b-form-group id="sc-notes-container" label="Notes">
                <b-form-textarea v-model="form.notes" placeholder="Enter notes" rows="3" max-rows="4"></b-form-textarea>
            </b-form-group>

            <b-button class="sc-button-primary" type="submit" variant="primary">Add</b-button>
        </b-form>
    </div>
</template>

<script>
import APICalls from "./APICalls";

export default {
    name: "NewCustomer",
    data() {
        return {
            form: {},
        };
    },
    methods: {
        onSubmitNewCustomer(event) {
            event.preventDefault();
            this.createNewCustomer();
        },
        async createNewCustomer() {
            let response = await APICalls.createCustomer(this.form);
            console.log(response);
            this.$toasted.show("New customer created", { position: "bottom-center", className: "sc-toast-success", duration: 6000 });
            this.form = {};
            this.$router.back();
        },
    },
};
</script>

<style lang="scss">
#sc-new-customer {
    form {
        margin-top: 20px;

        .col-form-label {
            font-size: 20px;
        }

        #sc-companyname-container,
        #sc-customername-container,
        #sc-balance-container,
        #sc-carryover-container {
            display: inline-block;
            width: 20%;
        }
        #sc-standing-container {
            display: inline-block;
            width: 20%;
        }

        #sc-customername-container,
        #sc-balance-container,
        #sc-carryover-container {
            padding-left: 10px;
        }
        #sc-standing-container {
            padding-left: 10px;
        }

        #sc-email-container,
        #sc-phone-container,
        #sc-productsid-container {
            display: inline-block;
            width: 25%;
        }

        #sc-truck-container,
        #sc-quickbooksid-container {
            display: inline-block;
            width: 12%;
        }

        #sc-phone-container,
        #sc-truck-container,
        #sc-quickbooksid-container,
        #sc-productsid-container {
            padding-left: 20px;
        }

        #sc-address-container,
        #sc-notes-container {
            display: inline-block;
            width: 50%;
        }

        #sc-notes-container {
            padding-left: 50px;
        }

        .sc-button-primary {
            width: 180px;
            margin-top: 10px;
        }
    }
}
</style>
